@import "/src/assets/styles/variables";

.detail {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .main {
    width: 100%;
    .content {
      width: 100%;
    }
  }
}
