@import "/src/assets/styles/variables";

.custom-swiper {
  .swiper-slide {
    // text-align: left;
    width: auto;
    .ant-image {
      @media screen and (max-width: $m) {
        width: 100%;
      }
    }
  }
}
