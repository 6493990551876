@import "/src/assets/styles/variables";

.preferred-project {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  .ant-card {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
    .ant-card-cover {
      padding: 10px;
      .card-cover {
        border-radius: 4px;
        height: 200px;
        @media screen and (max-width: $m) {
          height: 360px;
        }
        @media screen and (max-width: $s) {
          height: 200px;
        }
      }
    }
    .ant-card-body {
      padding: 0 0 90px 0;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $m) {
        padding: 0 0 94px 0;
      }
      &::after {
        display: none;
      }
      .name {
        color: var(--Primary);
        font-size: var(--fontSetting-normal);
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding: 0 10px;
        color: linear-gradient(90deg, #f9e0c2 0%);
        margin-bottom: 6px;
      }
    }
  }
}
