@import "/src/assets/styles/variables";

.horizontalSwiper {
  &:not(:first-child) {
    margin-top: 28px;
    @media screen and (max-width: $m) {
      margin-top: 10px;
    }
  }
  .swiperSlide {
    border-radius: 30px;
    background: #fff;
    color: #000;
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    padding: 10px 40px 10px 14px;
    transform: translateX(50px);
    @media screen and (max-width: $m) {
      padding: 6px 20px 6px 14px;
      transform: unset;
    }
  }
}
