@import "/src/assets/styles/variables";

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 44px 21px;
  @media screen and (max-width: $l) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: $m) {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
  .card {
    position: relative;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: var(--fontSetting-large);
    font-weight: 700;
    line-height: normal;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $s) {
      padding-bottom: 50px;
    }
    .imgContainer {
      width: 100%;
      & > div {
        width: 100%;
      }
      .img {
        border-radius: 6px 6px 0 0;
        height: 240px;
        @media screen and (max-width: $m) {
          height: 450px;
        }
        @media screen and (max-width: 700px) {
          height: 340px;
        }
        @media screen and (max-width: $s) {
          height: 220px;
        }
      }
    }
    .title {
      flex: 1;
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      text-align: right;
      .divider {
        color: #dfdfdf;
        margin: 20px 0 0;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        .data {
          font-size: var(--fontSetting-normal);
        }
        .btnText {
          cursor: pointer;
          padding: 24px 0;
          text-decoration-line: underline;
          color: #000;
          font-size: var(--fontSetting-normal);
          font-weight: 500;
          line-height: normal;
          transition: all 0.3s ease-in-out;
          @media screen and (max-width: $s) {
            padding: 12px 0;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
