@import "/src/assets/styles/variables";

.userInfo {
  position: absolute;
  left: 0;
  width: 100vw;
  background: linear-gradient(90deg, #e8b87f 0%, #f7dbb9 100%);
  .container {
    width: calc($l - 10px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: var(--fontSetting-large);
    font-weight: 600;
    line-height: 210%; /* 46.2px */
    padding: 24px 0;
    position: relative;
    @media screen and (max-width: $l) {
      width: calc($m - 10px);
    }
    @media screen and (max-width: $m) {
      width: 90%;
      padding: 24px 0;
      align-items: flex-start;
      line-height: 140%;
    }
    .id {
      @media screen and (max-width: $m) {
        position: absolute;
        right: 0;
      }
    }
    .wallet {
      font-size: 44px;
      font-weight: 700;
      @media screen and (max-width: $m) {
        font-size: 34px;
        margin-top: 60px;
        padding: 10px 0;
      }
      @media screen and (max-width: $s) {
        padding: 0;
        font-size: 24px;
      }
    }
  }
}
