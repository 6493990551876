@import "/src/assets/styles/variables";

.tableContainer {
  width: calc($l - 10px);
  margin: 0 auto;
  box-shadow: 0px 2px 20px -5px var(--home_sheet_titlebg);
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .label {
    text-align: center;
    border-radius: 12px 12px 0px 0px;
    background: var(--home_sheet_titlebg);
    color: var(--home_sheet_titletext);
    font-size: var(--fontSetting-large);
    font-weight: 500;
    line-height: normal;
    padding: 16px 0;
  }
  .contentContainer {
    // height: 824px;
    overflow-y: hidden;
    background: var(--home_sheet_contentbg);
    border-radius: 0px 0px 12px 12px;
    .swiperSlide {
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--home_sheet_contenttext);
      font-size: var(--fontSetting-large);
      font-weight: 500;
      line-height: normal;
      padding: 4px 0;
      border-bottom: 0.5px solid var(--home_sheet_line);
      .img {
        width: 42px;
        @media screen and (max-width: $m) {
          width: 30px;
        }
      }
    }
  }
}
