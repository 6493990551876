@import "/src/assets/styles/variables";

.custom-table-swiper {
  .swiper-wrapper {
    max-height: 700px;
    transition-timing-function: linear;
    .swiper-slide {
      height: 50px !important;
    }
  }
}
