.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  img {
    display: block;
  }
  .title {
    color: var(--text_content);
    text-align: center;
    font-size: var(--fontSetting-huge);
    font-weight: 400;
    line-height: normal;
    transform: translateY(-10px);
  }
}
