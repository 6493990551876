@import "/src/assets/styles/variables";

.imgContainer {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: $l) {
    margin-left: 22px;
  }
  @media screen and (max-width: $s) {
    margin-left: 6px;
  }
}
