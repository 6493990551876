@import "/src/assets/styles/variables";

.normal-card {
  .ant-card-cover {
    padding: 0 10px;
    .cover-img {
      border-radius: 4px;
    }
  }
  .ant-card-body {
    padding: 20px;
    .watermark {
      .ant-image {
        position: absolute;
        right: 6px;
        bottom: 10px;
        .watermark-img {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}
