@import "/src/assets/styles/variables";

.timerCountDown {
  display: flex;
  align-items: center;
  margin-right: 20px;
  .timerBox {
    text-align: center;
    color: #000;
    font-size: var(--fontSetting-bigger);
    font-weight: 600;
    line-height: 130%; /* 28.6px */
    padding: 0 6px;
    position: relative;
    &:not(:last-child)::before {
      content: "";
      width: 1px;
      height: 24px;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .value {
      color: red;
    }
  }
}
