@import "/src/assets/styles/variables";

.custom-input {
  .ant-form-item {
    .ant-row {
      .ant-form-item-label {
        padding-bottom: 4px;
        & > label {
          color: var(--text_content);
          font-size: var(--fontSetting-normal);
          font-weight: 700;
          line-height: 140%; /* 30.8px */
        }
      }
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            // ProFormText
            .ant-input-affix-wrapper {
              border: 1px solid var(--input_login_stroke);
              border-radius: 4px !important;
              background: var(--input_login_bg);
              &.ant-input-affix-wrapper-readonly {
                .ant-input {
                  // opacity: 0.3;
                }
              }
              .ant-input {
                padding: 8px 10px;
                color: var(--text_content);
                font-size: var(--fontSetting-normal);
                font-weight: 500;
                line-height: 140%; /* 30.8px */
                &::placeholder {
                  color: var(--text_content);
                  opacity: 0.3;
                }
              }
              .ant-input-suffix {
                .ant-input-clear-icon,
                .anticon {
                  color: var(--text_content);
                  opacity: 0.5;
                }
              }
            }

            // ProFormDigit
            .ant-input-number {
              border: 1px solid var(--input_login_stroke);
              background: var(--input_login_bg);
              .ant-input-number-handler-wrap {
              }
              .ant-input-number-input-wrap {
                border-radius: 4px !important;
                .ant-input-number-input {
                  padding: 12px 10px;
                  color: var(--text_content);
                  font-size: var(--fontSetting-normal);
                  font-weight: 500;
                  line-height: 140%; /* 30.8px */
                  &::placeholder {
                    color: var(--text_content);
                    opacity: 0.3;
                  }
                }
              }
              &.ant-input-number-outlined {
                &:focus-within {
                  border: 1px solid var(--input_login_stroke) !important;
                }
                &:hover {
                  border: 1px solid var(--input_login_stroke) !important;
                }
              }
            }

            //ProSelect
            .ant-select {
              height: 42px;
              &.ant-select-open {
                .ant-select-selection-item {
                  color: var(--text_content);
                  opacity: 0.7;
                }
              }
              .ant-select-selector {
                color: var(--text_content);
                border: 1px solid var(--input_login_stroke);
                background: var(--input_login_bg);
                border-radius: 4px !important;
                text-align: left;
                .ant-select-selection-wrap {
                  padding: 8px 10px;
                  font-size: var(--fontSetting-normal);
                  .ant-select-selection-search {
                    .ant-select-selection-search-input {
                    }
                  }
                  .ant-select-selection-placeholder {
                    color: var(--text_content);
                    opacity: 0.3;
                  }
                }
              }
              .ant-select-arrow {
                color: var(--text_content);
                opacity: 0.3;
              }
            }

            //ProUpload
            .ant-upload-wrapper {
              color: var(--text_content);
            }

            .ant-input-number-group-addon,
            .ant-input-group-addon {
              border: 1px solid var(--btn_solid_light);
              background: var(--btn_solid_light);
              color: var(--input_text_accent);
            }
          }
        }
      }
    }
  }
}
