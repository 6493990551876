.app-ios-modal-content {
  font-size: var(--fontSetting-normal);
  .description {
    margin-bottom: 24px;
  }
  .step {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    .text {
      display: flex;
      align-items: center;
      .icon-box {
        width: 24px;
      }
      .step-hint {
        padding-left: 12px;
        & > p {
          margin: 0;
        }
      }
    }
    .img {
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      object-fit: cover;
    }
  }
}
