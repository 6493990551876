@import "/src/assets/styles/variables";

.share {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .customNormalTitleStyle {
    text-align: start;
  }
  .section {
    width: 100%;
    margin-bottom: 60px;
    @media screen and (max-width: $s) {
      margin-bottom: 30px;
    }
  }
  img {
    margin-bottom: 24px;
    width: 100%;
    padding: 0 104px;
    @media screen and (max-width: $m) {
      padding: 0;
    }
  }

  .footerText {
    color: var(--text_content);
    font-size: var(--fontSetting-bigger);
    font-weight: 500;
    text-align: left;
    width: 100%;
  }
}
