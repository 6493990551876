@import "/src/assets/styles/variables";

.featureItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  width: 100%;
  padding: 46px 32px;
  @media screen and (max-width: $l) {
    padding: 24px;
  }
  .imageFrame {
    border-radius: 8px;
    background: linear-gradient(
      135deg,
      var(--app_feature_icon_bg1) 0%,
      var(--app_feature_icon_bg2) 100%
    );
    padding: 5px;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .featureItemText {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    .featureItemTitle {
      color: var(--text_content);
      font-size: var(--fontSetting-26);
      font-weight: 700;
      width: 100%;
      text-align: left;
    }
    .featureItemDesc {
      color: var(--text_content, #fff);
      font-size: var(--fontSetting-normal);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
      text-align: left;
    }
  }
}
