@import "/src/assets/styles/variables";

.progress {
  height: 20px;
  border-radius: 14px;
  .text {
    font-size: var(--fontSetting-middle);
    font-weight: 700;
  }
}

.progress-antd {
  .ant-progress-outer {
    .ant-progress-inner {
      overflow: unset !important;
      .ant-progress-bg {
        overflow: unset !important;
        .ant-progress-text {
          color: var(--project_progress_percent);
          font-size: var(--fontSetting-small);
          font-weight: 700;
          border-radius: 11.5px;
          position: relative;
          .percent {
            position: absolute;
            content: "";
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: auto;
            height: 20px;
            padding: 0 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: var(--project_progress_percent_bg);
            @media screen and (max-width: $s) {
              height: 16px;
            }
          }
        }
      }
    }
  }
}
