@import "/src/assets/styles/variables";

.navigation {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--Header);
  transition: all 0.3s ease-in-out;
  &.styleBorderBottom {
    border-bottom: 1px solid var(--Primary);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
  }
  .row {
    height: 102px;
    @media screen and (max-width: $s) {
      height: 58px;
    }
  }
}
