@import "/src/assets/styles/variables";

.privacyAndTerms {
  width: 100%;
  margin: 0 auto;
  padding: 48px 0 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $l) {
    padding: 0 0 70px;
    width: 90%;
  }
  .banner {
    text-align: center;
  }
  .title {
    color: var(--text_content);
    text-align: center;
    font-size: var(--fontSetting-52);
    font-weight: 700;
    line-height: normal;
    margin: 34px 0;
    @media screen and (max-width: $l) {
      font-size: 30px;
    }
    @media screen and (max-width: $s) {
      font-size: 24px;
      margin: 16px 0;
    }
  }
  .content {
    margin-bottom: 56px;
    color: var(--text_content);
    font-size: var(--fontSetting-22);
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: $s) {
      margin-bottom: 24px;
    }
  }
  .customButtonStyle {
    height: 54px;
    min-width: 571px;
    @media screen and (max-width: $m) {
      height: 46px;
      min-width: 326px;
    }
    & > span {
      font-size: var(--fontSetting-large);
    }
  }
}
