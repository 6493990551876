.custom-button {
  &.adm-button {
    border: none;
    width: auto;
    height: 38px;
    border-radius: 6px;
    padding: 6px 18px;
    // background: linear-gradient(180deg, var(--btn_gradiet_1) 0%, var(--btn_gradiet_2) 100%);
    background-image: linear-gradient(
      90deg,
      var(--btn_gradiet_1) 0,
      var(--btn_gradiet_2) 51%,
      var(--btn_gradiet_1)
    );
    background-size: 200% auto;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-size: 200% auto;
      background-position: right;
    }
    & > span {
      color: var(--btn_text);
      font-size: var(--fontSetting-normal);
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
    }
  }
}
