:root {
  --border-bottom: 1px solid var(--adm-color-light);
}

html,
body {
  /* height: calc(var(--vh, 1vh) * 100); */
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-size: 14px;
}

#root,
.app {
  height: 100%;
}

@media screen and (max-width: 1080px) {
  html,
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 512px) {
  html,
  body {
    font-size: 12px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* .app .adm-nav-bar {
  padding: 0 16px;
} */
/* .app .adm-nav-bar .adm-nav-bar-title {
  font-size: var(--fontSetting-middle);
}

.app .adm-nav-bar .adm-nav-bar-right {
  font-size: var(--fontSetting-small);
} */
.app .adm-input-element {
  font-size: var(--fontSetting-middle);
}
.app input.adm-input-element::placeholder {
  font-size: var(--fontSetting-middle);
}
.adm-popup.adm-dropdown-popup .adm-button {
  height: 44px;
}
.adm-popup.adm-dropdown-popup .adm-selector-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 4px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* *::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-thumb {
  background-color: red;
} */

/* *::-webkit-scrollbar {
  width: 0px;
} */
/* *::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
} */
