@import "/src/assets/styles/variables";

.recharge {
  text-align: center;
  .radio {
    display: none;
    margin-bottom: 28px;
    @media screen and (max-width: $m) {
      display: none;
    }
  }
}
