@import "/src/assets/styles/variables";

.benefits {
  li {
    color: var(--text_content);
    font-size: var(--fontSetting-bigger);
    font-weight: 500;
    margin-bottom: 10px;
  }
}
