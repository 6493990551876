@import "/src/assets/styles/variables";

.aboutArea {
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 0 auto 100px;
  width: calc($l - 10px);
  @media screen and (max-width: $l) {
    width: 90%;
    flex-direction: column;
    gap: 20px;
  }
  @media screen and (max-width: $m) {
    margin: 0 auto 60px;
  }
  .top {
    display: none;
    @media screen and (max-width: $l) {
      display: unset;
      text-align: center;
      margin: 0 auto;
      width: 90%;
      & > div {
        width: 90%;
      }
    }
    @media screen and (max-width: $m) {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
    .img {
      border-radius: 12px;
    }
  }
  .left {
    width: 48%;
    @media screen and (max-width: $l) {
      width: 100%;
    }
    .section {
      color: var(--text_content);
      font-weight: 700;
      line-height: normal;
      font-size: var(--fontSetting-normal);
      &:not(:last-child) {
        margin-bottom: 24px;
        @media screen and (max-width: $m) {
          margin-bottom: 20px;
        }
      }
      .title {
        color: var(--about_sub_title);
        margin-bottom: 24px;
        background: var(--Primary);
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        @media screen and (max-width: $m) {
          margin-bottom: 20px;
        }
        &.mainTitle {
          color: var(--text_content);
          background: transparent;
          font-size: 46px;
          margin-bottom: 24px;
          @media screen and (max-width: $l) {
            font-size: 30px;
          }
        }
      }
      .content {
        font-weight: 500;
      }
    }
  }
  .right {
    text-align: right;
    flex: 1;
    @media screen and (max-width: $l) {
      display: none;
    }
    .img {
      width: 90%;
      border-radius: 12px;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    }
  }
}
