@import "/src/assets/styles/variables";

.custom-card {
  .ant-card {
    .ant-card-head {
      border: 0;
      padding: 30px 36px 0;
      @media screen and (max-width: $m) {
        padding: 16px 22px 0;
      }
      .ant-card-head-wrapper {
        text-align: start;
        .ant-card-head-title {
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    .ant-card-body {
      padding: 30px 36px;
      @media screen and (max-width: $m) {
        padding: 14px 22px;
      }
    }
  }
}
