.textLinearGradient {
  color: hsla(0, 0%, 50%, 0.2);
  background-clip: text;
  background-size: 0 100%;
  background-repeat: no-repeat;
  animation: animateText linear forwards;
  animation-timeline: view();
  @keyframes animateText {
    to {
      background-size: 100% 100%;
    }
  }
}
