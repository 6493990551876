@import "/src/assets/styles/variables";

.navBarTitleBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc($l - 10px);
  margin: 0 auto;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  & .navBarTitleEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  .navBarTitle {
    color: var(--header_text_inactive);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 6px;
    @media screen and (max-width: $l) {
      justify-content: flex-start;
    }
    .logoContainer {
      flex: 1;
      .logo {
        cursor: pointer;
        width: 150px;
        @media screen and (max-width: $s) {
          width: 100px;
        }
      }
    }
    .navTitle {
      cursor: pointer;
      padding: 6px 10px;
      display: flex;
      align-items: flex-end;
      gap: 4px;
      transition: all 0.3s ease-in-out;
      @media screen and (max-width: $l) {
        display: none;
      }
      &:hover {
        color: var(--header_text_active);
      }
      &.active {
        color: var(--header_text_active);
      }
      .icon {
        margin-left: 4px;
      }
      .welfareCenter {
        color: var(--header_text_highlight);
      }
    }
    .buttonGroup {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 0 10px;
      @media screen and (max-width: $l) {
        margin: 0;
      }
      @media screen and (max-width: $s) {
        gap: 6px;
      }
    }
    .customButtonStyle {
      @media screen and (max-width: $s) {
        height: 28px;
        padding: 0px 10px 2px;
      }
      &.signIn {
        background: transparent;
        border: 1px solid var(--Primary);
        & > span {
          color: var(--Primary);
        }
      }
    }
    .hamburger {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: 20px;
      display: none;
      @media screen and (max-width: $l) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media screen and (max-width: $s) {
        margin-left: 6px;
      }
      .line {
        width: 18px;
        height: 2px;
        border-radius: 1.5px;
        background: #efc99a;
        position: relative;
        transition: all 0.1s ease-in-out;
        &::before,
        &::after {
          content: "";
          width: 18px;
          height: 2px;
          border-radius: 1.5px;
          background: #efc99a;
          position: absolute;
          transition: all 0.3s ease-in-out;
        }
        &::before {
          top: -6px;
        }
        &::after {
          bottom: -6px;
        }
        &.open {
          background: transparent;
          &::before {
            top: 0;
            transform: rotate(45deg);
          }
          &::after {
            bottom: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
.right {
  color: #fff;
  font-size: var(--fontSetting-normal);
  font-weight: 700;
  line-height: normal;
  padding: 0;
  &:hover {
    color: #fff !important;
  }
}
