@import "/src/assets/styles/variables";

.project-container {
  .adm-infinite-scroll {
    grid-column-start: 1;
    grid-column-end: 4;
    @media screen and (max-width: $l) {
      grid-column-end: 3;
    }
    @media screen and (max-width: $m) {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
}
