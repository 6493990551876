@import "/src/assets/styles/variables";

.custom-navbar {
  height: 102px;
  width: 100%;
  z-index: 9998;
  background: #000;
  position: sticky;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 30px;
  color: #fff;

  @media screen and (max-width: $s) {
    height: 58px;
    padding: 0 20px;
  }
  .adm-nav-bar-left {
    .adm-nav-bar-back {
      padding: 0;
    }
    .back {
      font-size: var(--fontSetting-huge);
    }
  }
  .adm-nav-bar-title {
    font-weight: 700;
    line-height: normal;
    font-size: var(--fontSetting-large);
  }
  .adm-nav-bar-right {
    font-weight: 700;
    line-height: normal;
  }
}
