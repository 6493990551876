@import "/src/assets/styles/variables";

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
  align-items: center;
  @media screen and (max-width: $s) {
    grid-template-columns: repeat(1, 1fr);
  }
  .customButtonStyle {
    justify-self: flex-end;
    height: 54px;
    @media screen and (max-width: $s) {
      height: 46px;
      width: 100%;
    }
    & > span {
      font-size: var(--fontSetting-large);
    }
  }
}
