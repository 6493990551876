.imgContainer {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 !important;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
