@import "/src/assets/styles/variables";

.buttonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $l) {
    justify-content: flex-end;
  }
  .buttonRead,
  .buttonUnRead {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 40px;
    border-radius: 2px;
    @media screen and (max-width: $m) {
      transform: scale(0.75);
    }
  }
  .buttonRead {
    background: linear-gradient(180deg, var(--btn_gradiet_1) 0%, var(--btn_gradiet_2) 100%);
  }
  .buttonUnRead {
    background: var(--message_unread_bg);
  }
}
