@import "/src/assets/styles/variables";

.mine-result-popup {
  .adm-mask {
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    opacity: 0 !important;
    z-index: -2;
    &.show {
      opacity: 1 !important;
      z-index: 10;
    }
    .adm-mask-content {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .content {
        transform: translate(80vh, 0);
        transition: all 0.3s ease-in-out;
        position: relative;
        &.show {
          transform: translate(0, 0);
        }
        .text {
          position: absolute;
          top: 50%;
          left: 55%;
          transform: translate(-50%, -50%);
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 700;
          line-height: normal;
          .top {
            display: flex;
            align-items: flex-end;
            .divider {
              height: 12px;
              opacity: 0.7;
              margin-bottom: 4px;
            }
            .odd {
              color: #fff;
              font-size: 22px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
