.statItemContainer {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--Primary);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
    .image {
      width: 46px;
      height: 46px;
    }
    .buttonViewAll {
      cursor: pointer;
      border-radius: 15px;
      border: 1px solid var(--btn_solid_light);

      color: var(--btn_text_light);
      font-size: var(--fontSetting-normal);
      font-weight: 500;
      line-height: 30px;
      padding: 0 16px;
      height: 30px;
    }
  }
  .title {
    color: var(--text_content, #fff);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
  }
  .content {
    color: var(--text_content, #fff);
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }
}
