.left {
  text-align: left;
  flex: 1;
  color: #000;
  font-size: var(--fontSetting-normal);
  font-weight: 400;
  line-height: normal;
}
.right {
  color: #000;
  font-size: var(--fontSetting-normal);
  font-weight: 400;
  line-height: normal;
  .rightIcon {
    margin-left: 14px;
    font-size: 16px;
  }
}
.bold {
  font-size: var(--fontSetting-huge);
  font-weight: 700;
  margin-bottom: 6px;
}
