@import "/src/assets/styles/variables";

.androidDownload {
  padding: 20px;
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .logoContainer {
      display: flex;
      align-items: center;
      span {
        color: #000;
        font-size: var(--fontSetting-large);
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .appContainer {
    padding-top: 53px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    gap: 20px;
    .logo {
      border-radius: 12px;
      border: 1px solid #eee;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .appTitleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
      .title {
        color: #000;
        font-size: var(--fontSetting-huge);
        font-weight: 500;
        line-height: normal;
      }
      .desc {
        color: #0f8460;
        font-size: var(--fontSetting-middle);
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .displayContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
    .appInfoContainer {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      gap: 20px;
      .ratingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          img {
            margin-left: 8px;
          }
        }
      }
    }
    .border {
      content: "";
      width: 1px;
      height: 80px;
      background-color: #cbcbcb;
    }
    .qrContainer {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .qr {
        max-width: 100%;
      }
    }
  }
  .button {
    width: 100%;
    border-radius: 4px;
    background: #007c56;
    height: 40px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    color: #fff;
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: 40px;
    margin: 30px 0;
  }
  .utilsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    .linkContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        color: #0f8460;
        font-size: var(--fontSetting-middle);
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .deviceContainer {
    margin-bottom: 20px;
    span {
      color: #5e5e5e;
      font-size: var(--fontSetting-middle);
      font-weight: 500;
      line-height: normal;
      margin-left: 8px;
    }
  }
  .gallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Hides scrollbar */
    -ms-overflow-style: none; /* Hides scrollbar */
    img {
      cursor: pointer;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding-top: 28px;
    .leftFooter {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .aboutTitle {
        color: #5e5e5e;
        font-size: var(--fontSetting-middle);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;
      }
      p {
        color: #5e5e5e;
        font-size: var(--fontSetting-small);
        font-weight: 500;
      }
    }
  }
}
