@import "/src/assets/styles/variables";

.amountArea {
  background: linear-gradient(90deg, #e8b87f 0%, #f7dbb9 100%);
  text-align: left;
  padding: 28px 40px 38px;
  color: #000;
  font-size: var(--fontSetting-large);
  font-weight: 500;
  line-height: 210%; /* 46.2px */
  @media screen and (max-width: $s) {
    padding: 14px 22px 20px;
  }
  .money {
    font-size: 44px;
    font-weight: 700;
    @media screen and (max-width: $s) {
      font-size: 30px;
    }
  }
}
