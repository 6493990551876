@import "/src/assets/styles/variables";

.iframe {
  width: 100%;
  height: var(--home-trading-view-height);
  vertical-align: bottom;
  @media screen and (max-width: $m) {
    height: 80px;
  }
}
