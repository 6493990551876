@import "/src/assets/styles/variables";

.referrals {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .customNormalContainerStyle {
    width: 100%;
  }
  .totalPartners {
    margin: 20px 0;
    align-self: flex-start;
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    .totalPartnersValue {
      margin-left: 20px;
      color: var(--text_accent_60);
    }
  }
  .hint {
    margin: 30px 0 20px;
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
  .learnMore {
    color: var(--btn_solid_light);
  }
}
