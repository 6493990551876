@import "/src/assets/styles/variables";

.myIncome {
  text-align: center;
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .radio {
    display: none;
    margin-bottom: 28px;
    @media screen and (max-width: $m) {
      margin: 16px 0;
    }
  }
}
