@import "/src/assets/styles/variables";

.myProject {
  .radio {
    display: none;
    text-align: center;
    margin-bottom: 28px;
    @media screen and (max-width: $m) {
      margin: 16px 0;
    }
  }
}
