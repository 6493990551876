@import "/src/assets/styles/variables";

.swiperArea {
  width: 100vw;
  margin-top: 34px;
  @media screen and (max-width: $s) {
    margin-top: 26px;
  }
  .img {
    width: 42px;
    @media screen and (max-width: $m) {
      width: 30px;
    }
  }
  .content {
    margin: 0 24px 0 30px;
    white-space: nowrap;
    direction: ltr;
  }
}
