@import "/src/assets/styles/variables";

.chooseAreaBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 34px 0;
  .chooseArea {
    margin: 0 auto 30px;
    text-align: center;
    width: calc($l - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $l) {
      width: 90%;
    }
    @media screen and (max-width: $m) {
      margin: 0 auto 60px;
    }
    .title {
      color: var(--text_content);
      font-size: 46px;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: $l) {
        font-size: 30px;
      }
    }
    .subTitle {
      color: var(--text_content);
      font-size: var(--fontSetting-bigger);
      font-weight: 500;
      line-height: normal;
      max-width: 400px;
      margin: 20px auto 48px;
      @media screen and (max-width: $l) {
        margin: 12px 0 18px;
      }
    }
    .cardsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 12px;
      @media screen and (max-width: $l) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $m) {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
      }
      .card {
        position: relative;
        text-align: center;
        border: 0;
        @media screen and (max-width: $s) {
          & > div {
            padding: 6px;
          }
        }
        .imgContainer {
          width: 100%;
          & > div {
            width: 100%;
          }
          .img {
            border-radius: 6px 6px 0 0;
            height: 70px;
            width: auto;
            @media screen and (max-width: $s) {
              height: 30px;
            }
          }
        }
        .title {
          color: #ffb532;
          font-size: 38px;
          font-weight: 700;
          line-height: normal;
          margin: 36px 0 8px;
          @media screen and (max-width: $l) {
            font-size: var(--fontSetting-huge);
          }
          @media screen and (max-width: $s) {
            margin: 12px 0 2px;
          }
        }
        .content {
          color: #000;
          font-size: 28px;
          font-weight: 500;
          line-height: normal;
          @media screen and (max-width: $l) {
            font-size: var(--fontSetting-large);
          }
        }
      }
    }
  }
}
