.singleData {
  background: var(--table_bg1);
  border-radius: 12px;
  margin-bottom: 8px;
  &:nth-child(even) {
    background: var(--table_bg2);
    .row {
      &:not(:last-child) {
        border-bottom: 0.5px solid var(--card_line2);
      }
    }
  }
  .row {
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    &:not(:last-child) {
      border-bottom: 0.5px solid var(--card_line1);
    }
    .value {
      text-align: right;
      width: 60%;
      word-break: break-all;
    }
  }
}
