@import "/src/assets/styles/variables";

.recharge {
  display: flex;
  flex-direction: column;
  align-items: center;
  .customButtonStyle {
    height: 42px;
    width: 100%;
  }
  .hint {
    color: #ff5151;
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-top: 20px;
  }
  .selectContainer {
    position: relative;
    .extra {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--text_content);
      font-size: var(--fontSetting-normal);
      font-weight: 500;
      line-height: normal;
    }
  }
}
