@import "/src/assets/styles/variables";

.normalImages {
  .img {
    width: 78px;
    height: auto;
    @media screen and (max-width: $m) {
      width: 50px;
    }
  }
}
