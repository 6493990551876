@import "/src/assets/styles/variables";

.navbar-drawer {
  margin-top: 102px;
  @media screen and (max-width: $s) {
    margin-top: 58px;
  }
  .ant-drawer-mask {
  }
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      background: transparent;
      .ant-drawer-body {
        background: rgba(0, 0, 0, 0.8);
        color: var(--header_text_inactive);
        font-size: var(--fontSetting-large);
        font-weight: 500;
        line-height: 200%; /* 44px */
        text-align: right;
        padding: 30px 58px;
        @media screen and (max-width: $s) {
          padding: 16px;
        }
        .content {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          &:hover {
            color: var(--header_text_active);
          }
          &.active {
            color: var(--header_text_active);
          }
          .icon {
            margin-left: 4px;
          }
          .drawer-btn {
            min-width: 150px;
            &.signIn {
              background: transparent !important;
              border: 1px solid var(--Primary);
              & > span {
                color: var(--Primary);
              }
            }
          }
          .welfare-center {
            color: var(--header_text_highlight);
          }
        }
      }
    }
  }
}

.nav-dropdown {
  color: var(--header_text_inactive);
  &:hover {
    color: var(--header_text_active);
  }
}
.nav-overlay-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-active {
      background-color: var(--Primary) !important;
      color: #fff;
    }
  }
}

.drawer-dropdown {
  display: block;
  margin-bottom: 6px;
  color: var(--header_text_inactive);
  width: 150px;
  justify-self: flex-end !important;
  text-align: right;
  &:hover {
    color: unset;
  }
  &.ant-dropdown-open {
    color: var(--header_text_active);
  }
  .active {
    color: var(--header_text_active);
  }
}
.drawer-overlay-dropdown {
  .ant-dropdown-menu {
    width: 150px;
    .ant-dropdown-menu-item-active {
      background-color: var(--Primary) !important;
      color: #fff;
    }
  }
}
