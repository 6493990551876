$maxWidthInPc: 450px;
$betPanelHeight: 211px;
$betPanelAuto: 46px;
$headerHeight: 52px;
$historyBarHeight: 34px;
$l: 1080px;
$m: 768px;
$s: 512px;

:export {
  maxWidthInPc: $maxWidthInPc;
  betPanelHeight: $betPanelHeight;
  betPanelAuto: $betPanelAuto;
  headerHeight: $headerHeight;
  historyBarHeight: $historyBarHeight;
  l: $l;
  m: $m;
  s: $s;
}
