@import "/src/assets/styles/variables";

.footer {
  background: var(--footer_bg_color);
  position: relative;
  .container {
    width: calc($l - 10px);
    margin: 0 auto;
    padding: 44px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: $l) {
      width: 90%;
    }
    @media screen and (max-width: $s) {
      padding: 44px 0 60px;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 120px;
      @media screen and (max-width: $m) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media screen and (max-width: $s) {
        padding-bottom: 180px;
      }
      .leftContainer {
        width: 40%;
        color: #fff;
        font-size: var(--fontSetting-normal);
        font-weight: 500;
        line-height: normal;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $m) {
          width: 100%;
          margin-bottom: 44px;
        }
        .logo {
          width: 186px;
          margin-bottom: 20px;
          @media screen and (max-width: $m) {
            margin-bottom: 14px;
          }
        }
      }
      .rightContainer {
        display: flex;
        gap: 40px;
        @media screen and (max-width: $l) {
          gap: 20px;
        }
        @media screen and (max-width: $m) {
          flex-direction: column;
          gap: 44px;
        }
        .itemGroup {
          .itemTitle {
            color: var(--footer_item_title);
            font-size: var(--fontSetting-huge);
            font-weight: 700;
            line-height: normal;
            margin-bottom: 28px;
            @media screen and (max-width: $m) {
              margin-bottom: 8px;
            }
          }
          .itemOptions {
            .itemOption {
              color: #fff;
              font-size: var(--fontSetting-normal);
              font-weight: 500;
              line-height: 28px; /* 175% */
              cursor: pointer;
            }
            .marginTop {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .socialMedia {
      background: var(--footer_bg_sub_color);
      padding: 16px 0;
      color: var(--footer_social_text);
      font-size: var(--fontSetting-normal);
      font-weight: 300;
      line-height: normal;
      width: 100vw;
      margin: 62px auto;
      position: absolute;
      left: 0;
      bottom: 20px;
      @media screen and (max-width: $s) {
        margin: 80px auto;
      }
      .mediaContainer {
        width: calc($l - 10px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: $l) {
          width: 90%;
        }
        @media screen and (max-width: $m) {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }
        .iconContainer {
          display: flex;
          align-items: center;
          @media screen and (max-width: $s) {
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;
          }
          .iconGroup {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .address {
          margin-right: 180px;
          @media screen and (max-width: $m) {
            margin-right: 30px;
          }
          @media screen and (max-width: $s) {
            margin-right: 0;
          }
        }
        .email {
          color: var(--footer_social_text);
        }
      }
    }
    .bottom {
      align-self: center;
      color: var(--footer_social_text);
      font-size: var(--fontSetting-normal);
      font-weight: 300;
      line-height: normal;
    }
  }
}
