@import "/src/assets/styles/variables";

.user {
  height: 100%;
  .main {
    width: 100%;
    margin: 0 auto;
    padding-top: 200px;
    @media screen and (max-width: $m) {
      padding: 0;
    }
  }
}
