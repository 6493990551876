@import "/src/assets/styles/variables";

.teamCollapse {
  .label {
    color: #fff;
    font-size: var(--fontSetting-huge);
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img {
      width: auto;
      height: 100px;
      @media screen and (max-width: $s) {
        height: 80px;
      }
    }
    .extra {
      display: flex;
      align-items: center;
      @media screen and (max-width: $m) {
        flex-direction: column;
        align-items: flex-end;
      }
      .text {
        margin-left: 4px;
        @media screen and (max-width: $m) {
          font-weight: 400;
        }
        @media screen and (max-width: $s) {
          font-size: var(--fontSetting-normal);
        }
      }
    }
  }
}
