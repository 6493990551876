@import "/src/assets/styles/variables";
.radio {
  @media screen and (max-width: $m) {
    margin: 16px auto;
  }
}

.left {
  text-align: left;
  flex: 1;
  color: #000;
  font-size: var(--fontSetting-normal);
  font-weight: 400;
  line-height: normal;
  .money {
    font-size: var(--fontSetting-huge);
    font-weight: 700;
  }
  .time {
    margin-top: 6px;
  }
}
.right {
  color: #000;
  font-size: var(--fontSetting-normal);
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  .rightIcon {
    margin-left: 14px;
    font-size: 16px;
  }
}
