.custom-table {
  .ant-empty-normal {
    .ant-empty-description {
      color: #fff;
    }
  }
  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          border-radius: 12px;
          .ant-table-container {
            border-radius: 12px;
            box-shadow: 0px 2px 20px -5px var(--Primary);
            .ant-table-content {
              font-size: var(--fontSetting-normal);
              & > table {
                border-radius: 12px;
                background: var(--bg-color2);
                thead {
                  tr {
                    th {
                      &:first-child {
                        border-top-left-radius: 12px;
                      }
                      &:last-child {
                        border-top-right-radius: 12px;
                      }
                    }
                  }
                }
                .ant-table-thead {
                  & > tr {
                    .ant-table-cell {
                      text-align: center;
                      background: var(--Primary);
                      border: 0;
                      height: 56px;
                    }
                    & > th {
                      color: #fff;
                      font-size: var(--fontSetting-large);
                      padding-top: 0;
                      padding-bottom: 0;
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
                .ant-table-tbody {
                  text-align: center;
                  & > tr {
                    &.ant-table-placeholder {
                      background: var(--bg-color2);
                      & > td {
                        border-radius: 12px;
                      }
                      &:hover {
                        & > td {
                          background: var(--bg-color2);
                        }
                      }
                    }
                    & > td {
                      color: var(--text_content);
                      font-size: var(--fontSetting-normal);
                      background: var(--bg-color2);
                      border-bottom: none;
                    }
                    &:not(:last-child) {
                      & > td {
                        border-bottom: 0.5px solid var(--Primary);
                      }
                    }
                  }
                  .ant-table-row {
                    .ant-table-cell-row-hover {
                      background: var(--bg-color2);
                    }
                  }
                  .ant-empty-description {
                    color: var(--text_content);
                    opacity: 0.3;
                  }
                }
              }
            }
          }
        }
        .ant-pagination {
          .ant-pagination-prev,
          .ant-pagination-next {
            .ant-pagination-item-link {
              .anticon {
                color: var(--text_content);
              }
            }
          }
          .ant-pagination-item {
            border: 1px solid var(--text_content);
            &:hover {
              border: 0;
              background-color: var(--Primary) !important;
              & > a {
                color: #fff;
              }
            }
            &.ant-pagination-item-active {
              border: 0;
              background-color: var(--Primary);
              & > a {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
