@import "/src/assets/styles/variables";

.custom-tab {
  .ant-tabs {
    .ant-tabs-nav {
      @media screen and (max-width: $m) {
        display: none;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background: #fff;
            padding: 16px 24px;
            border: 0;
            border-radius: 0;
            margin: 0;
            min-width: 200px;
            @media screen and (max-width: $l) {
              min-width: 120px;
            }
            &.ant-tabs-tab-active {
              background: #fff3e6;
              position: relative;
              &::before {
                content: "";
                width: 6px;
                height: 100%;
                background: #dd974a;
                position: absolute;
                left: 0;
              }
              .ant-tabs-tab-btn {
                color: #000;
              }
            }
            .ant-tabs-tab-btn {
              color: rgba(0, 0, 0, 0.7);
              font-size: var(--fontSetting-bigger);
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      border: 0;
      .ant-tabs-content {
        .ant-tabs-tabpane {
          @media screen and (max-width: $m) {
            padding: 0;
          }
        }
      }
    }
  }
}
