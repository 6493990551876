@import "/src/assets/styles/variables";

.detail {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .inputContainer {
    width: 100%;
    margin: 28px 0;
    .wallet {
      color: var(--text_content);
      font-size: var(--fontSetting-normal);
    }
  }
  .customButtonStyle {
    height: 42px;
    width: 100%;
  }
  .customNormalContainerStyle {
    margin-top: 26px;
    .introContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 30px;
        color: var(--text_content);
        font-size: var(--fontSetting-huge);
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: $m) {
          margin-bottom: 16px;
        }
      }
      .content {
        width: 100%;
      }
    }
  }
}
