@import "/src/assets/styles/variables";

.teamDetail {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .radio {
    @media screen and (max-width: $m) {
      margin-bottom: 28px;
      margin: 16px 0;
    }
  }
}
