.vipContainer {
  width: 100%;
  padding: 40px 44px;
  border-radius: 12px;
  border: 1px solid var(--Primary, #15bf6f);
  margin-top: 32px;
  .title {
    color: var(--text_content, #fff);
    font-size: var(--fontSetting-44);
    font-weight: 700;
    margin-bottom: 32px;
  }
  .copy {
    color: var(--dashboard_copy);
  }
}
