@import "/src/assets/styles/variables";

.normalTitle {
  color: var(--text_content);
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  background: transparent;
  padding: 40px 0;
  @media screen and (max-width: $m) {
    font-size: 28px;
  }
}
