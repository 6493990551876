@import "/src/assets/styles/variables";

.trade {
  padding: 0 0 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text_content);
  font-size: var(--fontSetting-normal);
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: $l) {
    margin: 0 auto;
    width: 100%;
  }
  .payHint {
    text-align: center;
  }
  .paySubHint {
    margin: 18px 0 26px;
    border-radius: 4px;
    background: var(--deposit_form_alert_bg);
    padding: 10px;
    text-align: center;
  }
  .customButtonStyle {
    height: 42px;
    width: 100%;
  }
}
