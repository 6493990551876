.vip-container {
  .ant-form-item {
    margin-bottom: 0;
    .ant-row {
      .ant-form-item-label {
        padding-bottom: 10px;
      }
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            border-radius: 2px;
            overflow: hidden;
            // ProFormText
            .ant-input-affix-wrapper {
              border-radius: 0 !important;
              .ant-input {
                height: 40px;
              }
            }
            .ant-input-number-group-addon,
            .ant-input-group-addon {
              border: 1px solid var(--Secondary);
              border-radius: 0px 2px 2px 0px;
              background: var(--Secondary);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .custom-input {
    .ant-input-group-addon {
    }
  }
}
