@import "/src/assets/styles/variables";
.welcomeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 15px;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    var(--dashboard_welcome_bg1) 0%,
    var(--dashboard_welcome_bg2) 100%
  );
  color: var(--text_content, #fff);
  @media screen and (max-width: $s) {
    border-radius: 0 0 40% 40%;
    padding-bottom: 230px;
    margin-bottom: -210px;
  }
  .title {
    text-align: center;
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
  }
  .content {
    color: var(--text_content, #fff);
    text-align: center;
    font-size: var(--fontSetting-22);
    font-weight: 700;
    line-height: normal;
    margin-bottom: 22px;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
