@import "/src/assets/styles/variables";

.topArea {
  margin-bottom: 60px;
  .main {
    display: flex;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: $l) {
      flex-direction: column;
    }
    @media screen and (max-width: $m) {
      gap: 24px;
    }
    .img {
      width: 440px;
      @media screen and (max-width: $s) {
        width: 290px;
      }
    }
    .text {
      color: var(--text_content);
      font-size: 42px;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: $m) {
        font-size: 30px;
      }
      .title {
        margin-bottom: 40px;
        @media screen and (max-width: $m) {
          margin-bottom: 24px;
        }
      }
      .content {
        font-size: var(--fontSetting-bigger);
        font-weight: 500;
      }
    }
  }
}
