@import "/src/assets/styles/variables";

.projectContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media screen and (max-width: $l) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: $m) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: $s) {
    gap: 10px;
  }
}
