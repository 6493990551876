@import "/src/assets/styles/variables";

.steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  @media screen and (max-width: $l) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 32px;
  }
  @media screen and (max-width: $m) {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}
