@import "/src/assets/styles/variables";

.describeContainer {
  width: 100%;
  .perDays {
    margin: 0 10px 6px;
    color: var(--text_neutrals_100);
    font-size: var(--fontSetting-huge);
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    @media screen and (max-width: $s) {
      font-size: 28px;
      margin-bottom: 6px;
    }
    & > span {
      color: var(--text_neutrals_70);
      font-size: var(--fontSetting-large);
      font-weight: 600;
      line-height: normal;
      margin-left: 6px;
      @media screen and (max-width: $s) {
        margin-left: 6px;
      }
    }
  }
  .describe {
    color: var(--text_neutrals_100);
    display: flex;
    align-items: center;
    font-size: var(--fontSetting-middle);
    font-weight: 500;
    line-height: 190%; /* 41.8px */
    @media screen and (max-width: $l) {
      font-size: var(--fontSetting-normal);
    }
    &.red {
      color: red;
    }
    &.bold {
      font-weight: 700;
    }
    .label {
      margin-left: 4px;
    }
    .value {
      margin-left: 2px;
    }
  }
  .divider {
    background: rgba(0, 0, 0, 0.2);
    @media screen and (max-width: $l) {
      margin: 14px 0;
    }
  }
}
