.radio-container {
  .adm-radio {
    .adm-radio-icon {
      border-color: var(--text_content);
      width: 16px;
      height: 16px;
    }
    .adm-radio-content {
      font-size: var(--fontSetting-normal);
      color: var(--text_content);
      font-weight: 500;
      line-height: normal;
    }
  }
  .adm-radio-checked {
    .adm-radio-icon {
      border-color: var(--Primary);
      background-color: var(--Primary);
    }
  }
}
