@import "/src/assets/styles/variables";

.methods-custom-swiper {
  .swiper-wrapper {
    transition-timing-function: linear;
    .swiper-slide {
      width: auto;
      .ant-image {
        @media screen and (max-width: $m) {
          width: 100%;
        }
      }
    }
  }
}
