@import "/src/assets/styles/variables";

.team-collapse {
  width: 100%;
  .ant-collapse {
    background: transparent;
    .ant-collapse-item {
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 6px;
      border: 0;
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 12px 12px 0 0;
        }
      }
      .ant-collapse-header {
        border-radius: 12px;
        background: var(--Primary) !important;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 0 46px;
        display: flex;
        align-items: center;
        @media screen and (max-width: $m) {
          padding: 0 10px;
        }
        .ant-collapse-expand-icon {
          margin-left: 24px;
          @media screen and (max-width: $s) {
            margin-left: 0px;
          }
          .ant-collapse-arrow {
            font-size: 26px;
            color: #fff;
            @media screen and (max-width: $s) {
              font-size: 20px;
            }
          }
        }
        .ant-collapse-header-text {
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 700;
          line-height: normal;
        }
      }
      .ant-collapse-content {
        border-radius: 0 0 12px 12px;
        background: transparent;
        box-shadow: 0px 2px 20px -5px var(--Primary);
        .ant-collapse-content-box {
          padding: 36px 0;
          margin: 0;
          color: #fff;
          font-size: var(--fontSetting-large);
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .commissions-table {
    .ant-table-wrapper {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-table {
            border-radius: 0;
            .ant-table-container {
              .ant-table-content {
                font-size: var(--fontSetting-normal);
                & > table {
                  border-radius: 0;
                  background: transparent;
                  thead {
                    tr {
                      th {
                        &:first-child {
                          border-top-left-radius: 0;
                        }
                        &:last-child {
                          border-top-right-radius: 0;
                        }
                      }
                    }
                  }
                  .ant-table-thead {
                    & > tr {
                      .ant-table-cell {
                        text-align: center;
                        background: var(--bg-sub-color);
                        border: 0;
                        height: 56px;
                      }
                      & > th {
                        color: var(--text_content);
                        font-size: var(--fontSetting-normal);
                        padding-top: 0;
                        padding-bottom: 0;
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  .ant-table-tbody {
                    text-align: center;
                    & > tr {
                      & > td {
                        color: var(--text_content);
                        font-size: var(--fontSetting-normal);
                        background: var(--bg-color2);
                        border-bottom: none;
                      }
                      &:not(:last-child) {
                        & > td {
                          border-bottom: 0.5px solid var(--Primary);
                        }
                      }
                    }
                    .ant-table-row {
                      .ant-table-cell-row-hover {
                        background: transparent;
                      }
                    }
                    .ant-empty-description {
                      color: var(--text_content);
                      opacity: 0.3;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.detail-popup {
  .adm-popup-body {
    background: #f5f5f5;
  }
}
