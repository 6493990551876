@import "/src/assets/styles/variables";

.topArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--home-top-area-height-xl);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $l) {
    height: var(--home-top-area-height-l);
    justify-content: flex-start;
  }
  @media screen and (max-width: $s) {
    height: var(--home-top-area-height-s);
  }
  .left {
    text-align: center;
    width: calc($l - 10px);
    margin: 40px auto;
    z-index: 1;
    @media screen and (max-width: $l) {
      width: 90%;
    }
  }
}
