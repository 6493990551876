@import "/src/assets/styles/variables";

.top-area-carousel {
  .swiper {
    width: auto;
    height: 100%;
    padding: 0 0 100px;
    @media screen and (max-width: $l) {
      padding: 0 0 20px;
    }
    @media screen and (max-width: $s) {
      padding: 0 0 40px;
    }
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        justify-content: center;
        & > img {
          display: block;
          width: 100%;
          height: 460px;
          // object-fit: cover;
          border-radius: 10px;
          -webkit-box-reflect: below 1px linear-gradient(transparent, #0003);
          @media screen and (max-width: $l) {
            -webkit-box-reflect: unset;
          }
          @media screen and (max-width: $m) {
            height: 380px;
          }
          @media screen and (max-width: $s) {
            height: 200px;
          }
        }
        &.swiper-slide {
          opacity: 1;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
  }
}
