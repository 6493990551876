.blogs-pagination {
  margin-top: 20px;
  justify-self: flex-end;
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      .anticon {
        color: var(--text_content);
      }
    }
  }
  .ant-pagination-item {
    border: 1px solid var(--text_content);
    &:hover {
      border: 0;
      background-color: var(--Primary) !important;
      & > a {
        color: #fff;
      }
    }
    &.ant-pagination-item-active {
      border: 0;
      background-color: var(--Primary);
      & > a {
        color: #fff;
      }
    }
  }
}
