.buttonGroup {
  display: flex;
  align-items: center;
  gap: 20px;
  .customButtonStyle {
    justify-self: flex-end;
    width: 120px;
    height: 38px;
    &.cancel {
      background: transparent;
      border: 1px solid var(--btn_solid_light, #15bf6f);
      & > span {
        color: var(--btn_text_light);
      }
    }
    & > span {
      font-size: var(--fontSetting-large);
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .divider {
    margin: 0 auto 14px;
  }
}
