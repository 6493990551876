.custom-popover-menu {
  .adm-popover-inner {
    --background: rgba(0, 0, 0, 0.9);
    .adm-popover-menu-item {
      &:active:not(.adm-popover-menu-item-disabled) {
        background-color: var(--popover-background-color);
        &::after {
          border-bottom: none;
        }
      }
      padding: 8px;
      &:first-child {
        padding-top: 12px;
      }
      &:last-child {
        padding-bottom: 12px;
      }
      .adm-popover-menu-item-text {
        border-top: none;
        padding: 0;
        color: var(--popover-font-color);
        font-size: var(--fontSetting-middle);
        font-weight: 700;
      }
    }
  }
}
