@import "/src/assets/styles/variables";

.right {
  .custom-input {
    flex: 1;
    margin-right: 26px;
    @media screen and (max-width: $s) {
      width: 100%;
    }
    .ant-form-item {
      margin: 0;
      .ant-input-number {
        background: transparent;
      }
    }
  }
}
