@import "/src/assets/styles/variables";

.partner {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    margin-bottom: 30px;
  }
}
