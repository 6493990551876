@import "/src/assets/styles/variables";

.detail-modal {
  .ant-modal {
    .ant-modal-content {
      padding: 50px;
      @media screen and (max-width: $m) {
        padding: 50px 14px 14px;
      }
      .ant-modal-header {
        margin: 0;
        .ant-modal-title {
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 700;
          line-height: normal;
        }
      }
      .ant-modal-body {
        color: #000;
        font-size: var(--fontSetting-normal);
        font-weight: 400;
        line-height: normal;
      }
      .ant-modal-footer {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .contract {
          text-align: start;
          margin-top: 20px;
          width: 100%;
          .custom--card {
            display: flex;
            flex-direction: column;
            align-items: center;
            & > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
