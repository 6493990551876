@import "/src/assets/styles/variables";

.iosDownload {
  padding: 20px;
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .logoContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: #0a84ff;
        font-size: var(--fontSetting-middle);
        font-weight: 500;
      }
    }
  }
  .appContainer {
    padding-top: 53px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    gap: 20px;
    .logo {
      border-radius: 22px;
    }
    .appTitleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
      .title {
        color: #000;
        font-size: var(--fontSetting-huge);
        font-weight: 700;
        line-height: normal;
      }
      .desc {
        color: #a1a1a1;
        font-size: var(--fontSetting-small);
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .deviceContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    span {
      color: #5e5e5e;
      font-size: var(--fontSetting-middle);
      font-weight: 500;
      line-height: normal;
      margin-left: 8px;
    }
  }
  .displayContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Hides scrollbar */
    -ms-overflow-style: none; /* Hides scrollbar */
    .itemContainer {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      .title {
        color: #a9a9a9;
        text-align: center;
        font-size: var(--fontSetting-small);
        font-weight: 500;
        line-height: normal;
      }
      .content {
        color: #8e8e93;
        text-align: center;
        font-size: var(--fontSetting-large);
        font-weight: 500;
        line-height: normal;
      }
      .subContent {
        color: #8e8e93;
        text-align: center;
        font-size: var(--fontSetting-small);
        font-weight: 500;
        line-height: normal;
      }
    }
    .border {
      content: "";
      width: 1px;
      height: 32px;
      background-color: #cbcbcb;
    }
  }
  .gallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Hides scrollbar */
    -ms-overflow-style: none; /* Hides scrollbar */
    img {
      border-radius: 26px;
      cursor: pointer;
    }
  }
}
