@import "/src/assets/styles/variables";

.slogan {
  // @media screen and (max-width: $s) {
  //   text-align: center;
  //   transform: translateY(-100px);
  // }
  .mainSlogan {
    color: #fff;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    @media screen and (max-width: $l) {
      white-space: unset;
      font-size: 40px;
    }
    @media screen and (max-width: $s) {
      font-size: 30px;
    }
  }
  .subSlogan {
    color: #fff;
    font-size: var(--fontSetting-large);
    font-weight: 700;
    line-height: normal;
    margin: 16px 0 20px;
  }
  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    @media screen and (max-width: $s) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
    .customButtonStyle {
      height: 50px;
      & > span {
        font-size: var(--fontSetting-large);
      }
      @media screen and (max-width: $l) {
        height: 42px;
        & > span {
          font-size: var(--fontSetting-normal);
        }
      }
      &.signIn {
        background: var(--btn_solid_light);
      }
    }
  }
}
