@import "/src/assets/styles/variables";

.affiliate {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .candy1,
  .candy2 {
    position: absolute;
    left: 0;
    top: 10%;
    @media screen and (max-width: $l) {
      display: none;
    }
  }
  .candy2 {
    top: 40%;
  }
}
