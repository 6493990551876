@import "/src/assets/styles/variables";

.record {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .computer {
    cursor: pointer;
  }
}

.copyContainer {
  cursor: pointer;
  .copyIcon {
    margin-left: 2px;
  }
}
