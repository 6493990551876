@import "/src/assets/styles/variables";

.custom-modal {
  .ant-modal {
    .ant-modal-content {
      padding: 0;
      .ant-modal-header {
        text-align: center;
        padding: 18px 0;
        margin: 0;
        @media screen and (max-width: $s) {
          padding: 18px 0;
        }
        .ant-modal-title {
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 600;
          line-height: normal;
        }
      }
      .ant-modal-body {
        color: #000;
        font-size: var(--fontSetting-normal);
        font-weight: 400;
        line-height: normal;
        padding: 20px 36px;
        @media screen and (max-width: $s) {
          padding: 10px 36px 20px;
        }
      }
      .ant-modal-footer {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 14px;
      }
    }
  }
}
