@import "/src/assets/styles/variables";

.faq {
  width: 100%;
  .ant-collapse {
    .ant-collapse-item {
      margin-bottom: 20px;
      border: 0;
      @media screen and (max-width: $m) {
        margin-bottom: 12px !important;
      }
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 8px 8px 0 0;
        }
        .ant-collapse-content-active {
          border-radius: 0 0 8px 8px;
        }
      }
      .ant-collapse-header {
        background: var(--faq_bg) !important;
        padding: 24px 30px;
        border-radius: 8px;
        @media screen and (max-width: $s) {
          padding: 14px 16px;
        }
        .ant-collapse-expand-icon {
          .ant-collapse-arrow {
            color: var(--Primary);
            font-size: 32px;
            @media screen and (max-width: $l) {
              font-size: 32px !important;
            }
            @media screen and (max-width: $m) {
              font-size: 26px !important;
            }
            @media screen and (max-width: $s) {
              font-size: 20px !important;
            }
          }
        }
        .ant-collapse-header-text {
          color: var(--text_neutrals_100);
          font-size: var(--fontSetting-large);
          font-weight: 500;
          line-height: normal;
        }
      }
      .ant-collapse-content {
        background: var(--faq_bg) !important;
        .ant-collapse-content-box {
          padding: 16px 30px;
          margin: 0;
          color: var(--text_neutrals_100);
          font-size: var(--fontSetting-normal);
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
