@import "/src/assets/styles/variables";
.dashboardContainer {
  @media screen and (max-width: $l) {
    padding: 0 0 70px;
    width: 90%;
    margin: 0 auto;
  }
  .welcomeContainer {
    margin: 0 -12px;
  }
  .topContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .statisticsContainer {
      flex: 0.75;
    }
    .sideContainer {
      flex: 0.25;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
