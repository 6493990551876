@font-face {
  font-family: "TitilliumWeb";
  src: url("../../assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../../assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../../assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../../assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../../assets/fonts/TitilliumWeb-Black.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: "Airstrike";
  src: url("../../assets/fonts/Airstrike.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
