@import "/src/assets/styles/variables";

.home {
  width: 100%;
  padding-top: calc(
    var(--home-top-area-height-xl) + var(--home-trading-view-height) + var(--home-marquee-height)
  );
  @media screen and (max-width: $l) {
    padding-top: calc(
      var(--home-top-area-height-l) + var(--home-trading-view-height) + var(--home-marquee-height) +
        36px
    );
  }
  @media screen and (max-width: $s) {
    padding-top: calc(
      var(--home-top-area-height-s) + var(--home-trading-view-height) + var(--home-marquee-height) +
        36px
    );
  }
  .marqueeArea {
    position: absolute;
    top: 0;
    left: 0;
  }
  .container {
    width: 100%;
    .component {
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: $l) {
        width: 90%;
        margin: 0 auto;
      }
      @media screen and (max-width: $s) {
        padding: 30px 0;
      }
      .titleContainer {
        color: var(--home_section_title);
        font-size: 46px;
        font-weight: 700;
        line-height: normal;
        .title {
          @media screen and (max-width: $l) {
            text-align: center;
            font-size: 30px;
          }
        }
      }
      .subTitle {
        color: var(--text_content);
        font-size: var(--fontSetting-bigger);
        font-weight: 500;
        line-height: normal;
        margin: 10px 0 20px;
        text-align: center;
        @media screen and (max-width: $s) {
          margin: 10px 0 12px;
        }
      }
    }
  }
}
