.countdownContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  .countdown {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    & > div {
      background: rgba(0, 0, 0, 0.6);
      border-radius: 100%;
    }
  }
  .closeBtn {
    color: #fff;
    font-size: 20px;
  }
}
