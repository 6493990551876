@import "/src/assets/styles/variables";

.trade {
  .ant-statistic {
    .ant-statistic-content {
      color: #dd974a;
      font-size: var(--fontSetting-large);
      font-weight: 700;
    }
  }
  .ant-result {
    padding: 0;
    padding-top: 44px;
    @media screen and (max-width: $s) {
      .ant-result-icon {
        margin: 0;
        .anticon {
          width: 50px;
        }
      }
      .ant-result-title {
        font-size: 16px;
      }
      .ant-result-subtitle {
        font-size: 12px;
      }
      .ant-result-extra {
        margin-top: 52px;
      }
    }
    .ant-result-extra {
      display: flex;
      justify-content: flex-end;
    }
  }
}
