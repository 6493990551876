@import "/src/assets/styles/variables";

.myTeam {
  width: 100%;
  padding-bottom: 140px;
  .container {
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: $l) {
      width: 90%;
    }
  }
}
