@import "/src/assets/styles/variables";

.settingContainer {
  margin-bottom: 48px;
  border-radius: 2px 2px 0px 0px;
  border-top: 1px solid var(--project_detail_line);
  @media screen and (max-width: $m) {
    margin-bottom: 30px;
  }
  .setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    padding: 12px;
    border-radius: 0 0 2px 2px;
    border: 1px solid var(--project_detail_line);
    border-top: 0;
  }
}
