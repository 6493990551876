@import "/src/assets/styles/variables";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  .formBody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
    @media screen and (max-width: $s) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .customButtonStyle {
    width: 100%;
    height: 42px;
    margin: 30px 0;
    @media screen and (max-width: $s) {
      margin-top: 10px;
    }
  }
  .inviteCode {
    grid-column: 1 / -1;
  }
  .verification {
    display: flex;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1 / -1; //独占一行
    gap: 20px;
    @media screen and (max-width: $s) {
      gap: 10px;
      margin-bottom: 16px;
    }
    .captcha {
      display: flex;
      align-items: center;
      gap: 12px;
      .image {
        width: 150px;
        cursor: pointer;
      }
      .reload {
        font-size: 20px;
        cursor: pointer;
        color: var(--btn_icon);
      }
      @media screen and (max-width: $s) {
        gap: 6px;
        .image {
          width: 124px;
        }
        .reload {
          font-size: 12px;
        }
      }
    }
  }
  .additional {
    grid-column: 1 / -1;
    @media screen and (max-width: $s) {
      margin-top: 20px;
    }
    .light {
      color: var(--btn_solid_light);
    }
  }
}
