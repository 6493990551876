@import "/src/assets/styles/variables";

.shareTable {
  border: 0.5px solid var(--Third);
  overflow-y: hidden;
  .container {
    width: 100%;
    overflow-y: scroll;
  }
  .row {
    display: flex;
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px 4px;
      border: 0.5px solid var(--Third);
      font-size: var(--fontSetting-normal);
      color: var(--text_content);
      line-height: 140%;
      &:first-child {
        flex: 1;
      }
      &.label {
        color: var(--text_sub_60);
        font-weight: 700;
      }
      &.cell {
        font-weight: 500;
      }
    }
  }
}
