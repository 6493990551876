@import "/src/assets/styles/variables";

.divider {
  margin: 20px 0;
  background: rgba(0, 0, 0, 0.2);
  @media screen and (max-width: $m) {
    margin: 14px 0;
  }
}

.close {
  color: #000;
  font-size: 26px;
}

.describeContainer {
  width: 100%;
  .describe {
    display: flex;
    align-items: center;
    .icon {
      font-size: 24px;
      color: #109a48;
      margin-right: 28px;
    }
    .label {
      color: rgba(0, 0, 0, 0.7);
      font-size: var(--fontSetting-large);
      font-weight: 400;
      line-height: 190%; /* 41.8px */
      flex: 1;
    }
    .value {
      color: #000;
      font-size: var(--fontSetting-large);
      font-weight: 600;
      line-height: 190%; /* 41.8px */
    }
    .divider {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.footer {
  width: 100%;
  min-height: 96px;
  border-radius: 4px;
  padding: 0 24px;
  background: linear-gradient(90deg, #f9e0c2 0%, #e1c097 100%);
  color: #000;
  font-size: var(--fontSetting-large);
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $m) {
    min-height: 54px;
  }
}
