@import "/src/assets/styles/variables";

.gift {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    .customButtonStyle {
      width: 100%;
      height: 42px;
    }
  }
  .customNormalContainerStyle {
    width: 100%;
    margin-bottom: 36px;
  }
}
