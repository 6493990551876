@import "/src/assets/styles/variables";

.card {
  color: var(--text_content);
  background: transparent;
  font-size: var(--fontSetting-huge);
  font-weight: 700;
  line-height: normal;
  background: transparent;
  border: 1px solid var(--Primary);
  padding: 20px 0 40px;
  transition: all 0.3s ease-in-out;
  position: relative;
  &:hover {
    box-shadow: 0px 2px 8px 0.5px var(--Primary);
    transform: translateY(-4px);
  }
  .imgContainer {
    display: flex;
    align-items: center;
    gap: 14px;
    @media screen and (max-width: $s) {
      flex-direction: column;
    }
    .img {
      width: 54px;
      height: 54px;
    }
  }
  .container {
    .content {
      color: var(--text_content);
      font-size: var(--fontSetting-normal);
      font-weight: 500;
      margin-top: 34px;
      @media screen and (max-width: $s) {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
