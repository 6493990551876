@import "/src/assets/styles/variables";

.bonuses {
  .subTitle {
    color: var(--text_sub_60);
    font-size: 26px;
    font-weight: 700;
    line-height: 130%; /* 33.8px */
    margin-bottom: 22px;
    @media screen and (max-width: $l) {
      font-size: var(--fontSetting-huge);
    }
  }
  .specialTips {
    color: var(--text_accent_60);
  }
  .content {
    color: var(--text_content, #fff);
    font-size: var(--fontSetting-bigger);
    font-weight: 500;
    line-height: 220%; /* 39.6px */
    margin-top: 22px;
  }
  .email {
    margin-left: 4px;
  }
}
