@import "/src/assets/styles/variables";

.normalContainer {
  border-radius: 12px;
  background: var(--bg-sub-color);
  padding: 36px 32px 24px;
  width: 750px;
  @media screen and (max-width: $m) {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
  }
}
