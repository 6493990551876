@import "/src/assets/styles/variables";

.homeModal {
  .divider {
    margin: 20px 0;
    background: rgba(0, 0, 0, 0.2);
  }
  .customButtonStyle {
    height: 42px;
  }
  .content {
    // width: 50%;
    // margin: 0 auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    & > div > img {
      margin-top: 30px;
      width: 90%;
    }
    & > img {
      margin-top: 30px;
      width: 90% !important;
    }
  }
}
