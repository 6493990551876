@import "/src/assets/styles/variables";

.dashboardArea {
  margin: 0 auto 100px;
  width: calc($l - 10px);
  @media screen and (max-width: $l) {
    width: 90%;
  }
  @media screen and (max-width: $m) {
    margin: 0 auto 60px;
  }
  .title {
    color: var(--text_content);
    font-size: 38px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    @media screen and (max-width: $l) {
      font-size: 30px;
    }
  }
  .statisticContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin: 32px 0 14px;
    @media screen and (max-width: $l) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $m) {
      margin: 20px 0 26px;
      gap: 12px 18px;
    }
    .statistic {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
      color: var(--about_dashboard_text);
      font-size: var(--fontSetting-normal);
      font-weight: 500;
      line-height: normal;
      border-radius: 12px;
      @media screen and (max-width: $l) {
        min-height: 80px;
      }
      @media screen and (max-width: $s) {
        flex-direction: column;
        min-height: 60px;
        height: 60px;
      }
      .value {
        margin-right: 2px;
      }
      .name {
      }
    }
  }
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    @media screen and (max-width: $l) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $m) {
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
    }
    .customCardStyle {
      padding: 10px 0 0;
      text-align: center;
      .customTitleStyle {
        font-size: var(--fontSetting-normal);
        font-weight: 500;
        width: 100%;
      }
      .customContentStyle {
        font-size: var(--fontSetting-normal);
        font-weight: 300;
        margin-top: 14px;
      }
    }
  }
}
