@import "/src/assets/styles/variables";

.giftAndRecord {
  text-align: center;
  .radio {
    display: none;
    margin-bottom: 28px;
    @media screen and (max-width: $m) {
      margin: 16px 0;
    }
  }
}
