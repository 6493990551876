@import "/src/assets/styles/variables";

.marquee {
  display: flex;
  align-items: center;
  background: var(--home_marquee_bg);
  width: 100vw;
  height: var(--home-marquee-height);
  @media screen and (max-width: $m) {
    height: 34px;
  }
  @media screen and (max-width: $s) {
    height: 22px;
  }
  .marqueeTextContainer {
    display: flex;
    align-items: center;
    width: 96%;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.7);
    font-size: var(--fontSetting-bigger);
    font-weight: 500;
    line-height: normal;
    overflow-x: hidden;
    white-space: nowrap;
    .icon {
      margin-right: 10px;
      .img {
        height: 29px;
        width: auto;
        @media screen and (max-width: $s) {
          height: 12px;
          transform: translateY(-1px);
        }
      }
    }
  }
}
