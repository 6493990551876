.affiliate {
  text-align: center;
  padding: 10px;
  .title {
    color: 000;
    font-size: var(--fontSetting-normal);
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  .progressContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .subTitle {
    color: rgba(0, 0, 0, 0.7);
    font-size: var(--fontSetting-middle);
    font-weight: 600;
    line-height: normal;
  }
}
