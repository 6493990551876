@import "/src/assets/styles/variables";

.main {
  height: calc(100% - 102px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  margin: 0 auto;
  @media screen and (max-width: $s) {
    height: calc(100% - 56px);
    flex: 1;
    // margin: 0;
  }
  &.noHeader {
    height: 100%;
    .container {
      padding-bottom: 0;
    }
  }
  .container {
    height: calc(100%);
    padding: 0 0 140px;
    width: calc($l - 10px);
    margin: 0 auto;
    & > div {
      height: 100%;
    }
    @media screen and (max-width: $l) {
      width: 100%;
      padding: 0 0 70px;
    }
  }
  .container::-webkit-scrollbar {
    display: none;
  }
}
