.table-tab {
  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background: #fff;
            padding: 4px 30px;
            border: 0;
            border-radius: 0;
            margin: 0;
            min-width: unset;
            &.ant-tabs-tab-active {
              background: transparent;
              position: relative;
              &::before {
                content: "";
                width: 6px;
                height: 100%;
                background: #dd974a;
                position: absolute;
                left: 0;
                display: none;
              }
              .ant-tabs-tab-btn {
                color: #f0c000;
              }
            }
            .ant-tabs-tab-btn {
              color: rgba(0, 0, 0, 0.7);
              font-size: var(--fontSetting-large);
              font-weight: 500;
              line-height: normal;
            }
          }
          .ant-tabs-ink-bar {
            height: 2px;
            background: #f0c000;
          }
        }
      }
    }
  }
}
