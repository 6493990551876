@import "/src/assets/styles/variables";

.appDownload {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .instructionContainer {
    margin-top: 80px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 140px;
    @media screen and (max-width: $m) {
      margin: 0 0 70px;
    }
    .leftContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .subTitle {
        color: var(--text_content);
        font-size: var(--fontSetting-large);
        font-weight: 500;
        line-height: 220%; /* 48.4px */
        @media screen and (max-width: $m) {
          text-align: center;
        }
      }
      .imageContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 42px;
        margin-top: 77px;
        @media screen and (max-width: $m) {
          width: 100%;
          margin-top: 24px;
        }
        .downloadLinks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 18px;
          @media screen and (max-width: $m) {
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-around;
          }
        }
        .qr {
          @media screen and (max-width: $m) {
            display: none;
          }
        }
      }
    }
    .imagePhoneContainer {
      text-align: center;
      .phone {
        width: 100%;
        height: auto;
        margin-top: 40px;
        @media screen and (max-width: $m) {
          width: 80%;
        }
      }
    }
  }
  .features {
    width: 100%;
  }
}
