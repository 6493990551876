@import "/src/assets/styles/variables";

.cs-float-button {
  .ant-float-btn-group {
    padding-bottom: 10px;
    @media screen and (max-width: $m) {
      padding-bottom: 80px;
    }
    @media screen and (max-width: $s) {
      padding-bottom: 70px;
    }
    .cs-float-btn {
      background: transparent;
      width: 80px;
      height: 80px;
      box-shadow: none;
      @media screen and (max-width: $l) {
        width: 80px;
        height: 80px;
      }
      @media screen and (max-width: $m) {
        width: 68px;
        height: 68px;
      }
      @media screen and (max-width: $s) {
        width: 52px;
        height: 52px;
      }
      .ant-float-btn-body {
        background: transparent;
        .ant-float-btn-content {
          width: 100%;
          padding: 0;
          .ant-float-btn-icon {
            margin: 0;
            width: 100%;
            .float-img {
              width: 80px;
              height: 80px;
              @media screen and (max-width: $l) {
                width: 80px;
                height: 80px;
              }
              @media screen and (max-width: $m) {
                width: 68px;
                height: 68px;
              }
              @media screen and (max-width: $s) {
                width: 52px;
                height: 52px;
              }
            }
          }
        }
      }
    }
  }
}
.cs-list {
  .ant-list {
    padding-bottom: 50px;
    border-radius: 0;
    border: 0;
    .ant-spin-container {
      .ant-list-items {
        .ant-list-item {
          cursor: pointer;
          color: #000;
          font-size: var(--fontSetting-large);
          font-weight: 700;
          line-height: normal;
          border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        }
      }
    }
  }
}

.notranslate {
  @media screen and (max-width: $m) {
    bottom: 78px !important;
  }
}

.wrap__soDR9 {
  @media screen and (max-width: $s) {
    bottom: 40px !important;
    transform: scale(0.8);
    .button__VPX41 {
      margin: 0 4px 0 0 !important;
    }
  }
}
