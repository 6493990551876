@import "/src/assets/styles/variables";

.form {
  width: 100%;
  .customButtonStyle {
    width: 100%;
    height: 42px;
    margin: 30px 0;
    @media screen and (max-width: $s) {
      margin-top: 10px;
    }
  }
  .additional {
    display: flex;
    align-items: center;
    color: var(--text_content);
    font-size: var(--fontSetting-normal);
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: $s) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .customRadioContainerStyle {
      flex: 1;
    }
    .question {
      @media screen and (max-width: $s) {
        align-self: flex-end;
      }
      .registerNow {
        color: var(--btn_solid_light);
        cursor: pointer;
        margin-left: 2px;
      }
    }
  }
}
