@import "/src/assets/styles/variables";

.record {
  @media screen and (max-width: $l) {
    width: 90%;
    margin: 0 auto;
  }
  .computer {
    cursor: pointer;
    width: 48px;
    @media screen and (max-width: $m) {
      width: 36px;
    }
  }
}
.content {
  color: var(--text_content);
  font-size: var(--fontSetting-normal);
  font-weight: 500;
  line-height: normal;
  .row {
    padding: 16px 12px;
    border-radius: 2px;
    border: 1px solid var(--input_login_stroke);
    background: var(--input_login_bg);
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.copyContainer {
  cursor: pointer;
  .copyIcon {
    margin-left: 2px;
  }
}
