@import "/src/assets/styles/variables";

.work {
  margin-bottom: 60px;
  .main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    gap: 20px;
    @media screen and (max-width: $l) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $m) {
      grid-template-columns: repeat(1, 1fr);
    }
    .card {
      color: var(--text_content);
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      .title {
        margin: 24px 0 12px;
        @media screen and (max-width: $m) {
          margin: 18px 0 8px;
        }
      }
      .content {
        font-size: var(--fontSetting-normal);
        font-weight: 500;
      }
    }
  }
}
