@import "/src/assets/styles/variables";

.partners {
  margin-top: 20px;
  .img {
    width: 150px;
    height: auto;
    @media screen and (max-width: $l) {
      width: 130px;
    }
    @media screen and (max-width: $m) {
      width: 110px;
    }
  }
}
