@import "/src/assets/styles/variables";

.right {
  .single-amount-input {
    .ant-input-number-input-wrap {
      .ant-input-number-input {
        color: var(--btn_text_light) !important;
      }
    }
  }
}
