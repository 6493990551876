@import "/src/assets/styles/variables";

.signup {
  .mainBg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: auto;
    .main {
      width: calc($l - 10px);
      margin: 0 auto;
      padding: 100px 0;
      @media screen and (max-width: $l) {
        width: 90%;
      }
      .form {
        border-radius: 12px;
        background: var(--signin_bg);
        padding: 60px 48px 50px;
        max-width: 700px;
        height: 800px;
        @media screen and (max-width: $m) {
          padding: 30px;
        }
        @media screen and (max-width: $s) {
          height: 1100px;
        }
        .title {
          color: var(--text_content);
          font-size: 26px;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 26px;
        }
      }
    }
  }
}
