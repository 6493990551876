@import "/src/assets/styles/variables";

.advantages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 38px 16px;
  @media screen and (max-width: $l) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: $m) {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}
