@import "/src/assets/styles/variables";

.footer-menu {
  display: none;
  @media screen and (max-width: $m) {
    display: unset;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: var(--tabbar_bg);
    padding: 16px 0;
    z-index: 100;
  }

  @media screen and (max-width: $s) {
    padding: 10px 0;
  }
  .ant-row {
    .gutter-row {
      .item {
        cursor: pointer;
        text-align: center;
        .img {
          width: auto;
          height: 32px;
          @media screen and (max-width: $s) {
            height: 20px;
          }
        }
        .text {
          color: var(--tabbar_text_inactive);
          font-size: var(--fontSetting-small);
          font-weight: 700;
          line-height: 135%; /* 32.4px */
          letter-spacing: 0.3px;
          &.active {
            color: var(--tabbar_text_active);
          }
        }
      }
    }
  }
}
