.ant-message {
  margin-top: calc(50vh - 57px);
  .ant-message-notice-wrapper {
    .ant-message-notice {
      .ant-message-notice-content {
        background: #000;
        min-width: 90;
        max-width: 90;
        // min-height: 115px;
        padding: 16px;
        opacity: 0.85;
        border-radius: 12px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .custom-content {
          // height: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-between;
          // align-items: center;
          .content {
            font-size: var(--fontSetting-middle);
            font-weight: 500;
            line-height: 1.57;
            text-align: center;
            color: #fff;
            margin-top: 5px;
            word-break: break-word;
          }
        }
      }
    }
  }
}
