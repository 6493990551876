@import "/src/assets/styles/variables";

.fill-modal {
  .ant-modal {
    color: #fff;
    font-size: var(--fontSetting-normal);
    .ant-modal-content {
      border-radius: 12px;
      background: var(--popup_bg);
      padding: 0;
      .ant-modal-close {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: #000;
        color: #fff;
        .ant-modal-close-x {
          font-size: 24px;
        }
        @media screen and (max-width: $s) {
          width: 24px;
          height: 24px;
          .ant-modal-close-x {
            font-size: 16px;
          }
        }
      }
      .ant-modal-header {
        border-radius: 12px 12px 0px 0px;
        border-bottom: 1px solid var(--popup_header_stroke, #3e4c74);
        background: var(--popup_header, #29324a);
        padding: 22px;
        text-align: left;
        @media screen and (max-width: $s) {
          padding: 12px;
        }
        .ant-modal-title {
          color: #fff;
          font-size: var(--fontSetting-huge);
          font-weight: 700;
          line-height: normal;
        }
      }
      .ant-modal-body {
        min-height: 130px;
        padding: 12px;
      }
      .ant-modal-footer {
        margin: 0;
        padding: 22px;
        padding-top: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}
