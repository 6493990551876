@import "/src/assets/styles/variables";

.project {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .banner {
    margin: 0 auto 24px;
    text-align: center;
    @media screen and (max-width: $s) {
      margin-bottom: 12px;
    }
    & > div > img {
      border-radius: 4px;
    }
  }
}
