@import "/src/assets/styles/variables";

.horizontalSwiper {
  &:not(:first-child) {
    margin-top: 28px;
    @media screen and (max-width: $m) {
      margin-top: 10px;
    }
  }
  .swiperSlide {
    display: flex;
    align-items: center;
    .img {
      width: 1300px;
      @media screen and (max-width: $m) {
        width: 1000px;
      }
    }
  }
}
