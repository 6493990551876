@import "/src/assets/styles/variables";

.cardFooter {
  position: absolute;
  bottom: 0;
  padding-bottom: 16px;
  width: 100%;
  @media screen and (max-width: $s) {
    padding-bottom: 18px;
  }
  .divider {
    margin: 16px 0;
  }
  .footerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 34px;
    .customButtonStyle {
      @media screen and (max-width: $m) {
        height: 46px;
        width: 90%;
      }
    }
    .returnFooter {
      border-radius: 4px;
      background: linear-gradient(90deg, #f9e0c2 0%, #e1c097 100%);
      color: #000;
      text-align: center;
      font-size: var(--fontSetting-large);
      font-weight: 700;
      line-height: 130%; /* 28.6px */
      padding: 20px 0px;
      width: 90%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @media screen and (max-width: $m) {
        padding: 16px 0;
      }
      .rightIcon {
        @media screen and (max-width: $m) {
          font-size: 20px;
        }
        @media screen and (max-width: $s) {
          font-size: 12px;
        }
      }
    }
    .buyFooter {
      width: 100%;
      padding: 0 10px;
      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 28px;
        .customButtonStyle {
          width: 100%;
          margin-bottom: 12px;
        }
        .viewDetail {
          background: #fff;
          border: 1px solid #dd974a;
          & > span {
            color: #dd974a;
          }
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      padding: 0 30px;
      @media screen and (max-width: $m) {
        padding: 0 18px;
        margin-top: 6px;
        cursor: pointer;
      }
      .rightIcon {
        cursor: pointer;
        @media screen and (max-width: $m) {
          font-size: 20px;
        }
        @media screen and (max-width: $s) {
          font-size: 12px;
        }
      }
      .text {
        color: #000;
        font-size: var(--fontSetting-normal);
        font-weight: 600;
        line-height: 130%; /* 28.6px */
        max-width: 50%;
        @media screen and (max-width: $m) {
          max-width: 80%;
        }
      }
      .viewDetail {
        background: #fff;
        border: 1px solid #dd974a;
        flex: 1;
        & > span {
          color: #dd974a;
        }
      }
    }
  }
}
