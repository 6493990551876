@import "/src/assets/styles/variables";

.detail {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: $l) {
    width: 90%;
  }
  .mainContainer {
    display: flex;
    justify-content: space-between;
    .main {
      width: 65%;
      @media screen and (max-width: $l) {
        width: 100%;
      }
      .imgContainer {
        width: 100%;
        & > div {
          width: 100%;
          .img {
            border-radius: 12px;
          }
        }
      }
      .title {
        margin: 58px 0;
        color: var(--text_content);
        font-size: 44px;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: $l) {
          margin: 30px 0;
        }
        @media screen and (max-width: $s) {
          font-size: 20px;
          margin: 14px 0 24px;
        }
      }
      .content {
        width: 100%;
      }
    }
    .recent {
      width: 30%;
      @media screen and (max-width: $l) {
        display: none;
      }
      .list {
        border-radius: 12px;
        background: var(--blog_recent_bg);
        border: 0;
        .title {
          color: var(--text_content);
          font-size: 44px;
          font-weight: 700;
          line-height: normal;
        }
        .listItem {
          display: flex;
          align-items: center;
          gap: 18px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.7;
          }
          .img {
            width: 100px;
          }
          .subTitle {
            color: var(--text_content);
            font-size: var(--fontSetting-large);
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
    }
  }
}
