@import "/src/assets/styles/variables";

.greener {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $m) {
    flex-direction: column;
  }
  .left {
    width: 46%;
    line-height: normal;
    font-weight: 500;
    @media screen and (max-width: $m) {
      width: 100%;
    }
    .extra {
      color: var(--text_sub_60);
      font-size: var(--fontSetting-normal);
    }
    .title {
      margin: 20px 0;
      font-size: 46px;
      font-weight: 700;
      @media screen and (max-width: $l) {
        font-size: 38px;
      }
      @media screen and (max-width: $s) {
        font-size: 30px;
      }
    }
    .subTitle {
      font-size: var(--fontSetting-bigger);
    }
    .customButtonStyle {
      background: transparent;
      border: 1px solid var(--Third);
      margin-top: 60px;
      @media screen and (max-width: $m) {
        margin: 24px 0;
      }
      & > span {
        color: var(--Third);
      }
    }
  }
  .right {
    width: 46%;
    @media screen and (max-width: $m) {
      width: 100%;
      margin-bottom: 20px;
    }
    .img {
      width: 100%;
      height: auto;
      border-radius: 4px;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      &.img1 {
        transform: scale(0.1);
      }
      &.img2 {
        transform: scale(0.1);
      }
      &.show {
        opacity: 1;
        transform: translateX(0) scale(1) rotate(0deg);
      }
    }
  }
}
