.activityContainer {
  width: 100%;
  padding: 18px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  background: var(--bg-sub-color);
  min-height: 188px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .title {
      color: var(--text_content, #fff);
      font-size: var(--fontSetting-bigger);
      font-weight: 700;
      line-height: normal;
    }
    .buttonViewAll {
      cursor: pointer;
      border-radius: 15px;
      border: 1px solid var(--btn_solid_light);

      color: var(--btn_text_light);
      font-size: var(--fontSetting-normal);
      font-weight: 500;
      line-height: 30px;
      padding: 0 16px;
      height: 30px;
    }
  }
  .spin {
    width: 100%;
    text-align: center;
  }
  .activityItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
    &::after {
      opacity: 0;
    }
    .activityItem {
      width: 100%;
      color: var(--text_content, #fff);
      font-size: var(--fontSetting-normal);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .activityTitle {
        margin-left: 4px;
        font-weight: 500;
        text-align: left;
        flex: 1;
      }
      .activityContent {
        max-width: 40%;
        text-align: right;
        font-weight: 700;
        color: var(--Primary, #15bf6f);
      }
    }
  }
}
