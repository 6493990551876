@import "/src/assets/styles/variables";

.custom-drawer-popup {
  .adm-mask {
    // max-width: $maxWidthInPc;
    // left: 50%;
    // transform: translateX(-50%);
  }
  .adm-popup-body {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    .container {
      width: 100%;
    }
  }
}
