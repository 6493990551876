@import "/src/assets/styles/variables";

.walletContainer {
  .main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 46px 0;
    @media screen and (max-width: $m) {
      padding: 0;
    }
  }
}
