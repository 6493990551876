@import "/src/assets/styles/variables";

.licenseBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 34px 0;
  .license {
    display: flex;
    align-items: center;
    gap: 76px;
    width: calc($l - 10px);
    margin: 0 auto 30px;
    position: relative;
    @media screen and (max-width: $l) {
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
      gap: 38px;
    }
    @media screen and (max-width: $m) {
      gap: 28px;
    }
    .left {
      .text {
        color: var(--text_content);
        font-size: var(--fontSetting-large);
        font-weight: 700;
        line-height: 200%; /* 44px */
        margin-bottom: 60px;
        @media screen and (max-width: $l) {
          margin-bottom: 26px;
        }
        @media screen and (max-width: $s) {
          margin-bottom: 16px;
        }
      }
      .buttonGroup {
        display: flex;
        align-items: center;
        gap: 22px;
        @media screen and (max-width: $s) {
          flex-direction: column;
          gap: 16px;
        }
        .customButtonStyle {
          width: auto;
          height: 38px;
          background: var(--btn_solid_light);
          @media screen and (max-width: $s) {
            width: 100%;
            height: 46px;
          }
          & > span {
            font-size: var(--fontSetting-normal);
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 18px;
      @media screen and (max-width: $s) {
        gap: 12px;
      }
      .img {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
