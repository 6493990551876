@import "/src/assets/styles/variables";

.custom-radio {
  .ant-radio-group {
    @media screen and (max-width: $m) {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    .ant-radio-button-wrapper {
      border: 1px solid var(--Primary);
      background: transparent;
      color: var(--Primary);
      font-size: var(--fontSetting-large);
      font-weight: 700;
      line-height: normal;
      height: 58px;
      min-width: 150px;
      text-align: center;
      padding: 16px;
      @media screen and (max-width: $m) {
        height: 36px;
        padding: 6px;
        min-width: unset;
        flex: 1;
      }
      &::before {
        background: var(--Primary);
      }
      &.ant-radio-button-wrapper-checked {
        color: #fff;
        background: var(--Primary);
        &:hover {
          border-color: var(--Primary);
        }
      }
      .ant-radio-button {
      }
    }
  }
}
