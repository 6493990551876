@import "/src/assets/styles/variables";

.about {
  width: 100%;
  .container {
    width: 100%;
    margin: 0 auto;
    .banner {
      margin: 0 auto 24px;
      width: calc($l - 10px);
      border-radius: 4px;
      @media screen and (max-width: $l) {
        width: 90%;
      }
      @media screen and (max-width: $m) {
        margin-bottom: 24px;
      }
      @media screen and (max-width: $s) {
        margin-bottom: 8px;
      }
      & > div > img {
        border-radius: 4px;
      }
    }
  }
  .star {
    position: absolute;
    top: 5%;
    left: 0;
    @media screen and (max-width: $m) {
      display: none;
    }
  }
  .candy {
    position: absolute;
    top: 46%;
    left: 0;
    @media screen and (max-width: $m) {
      display: none;
    }
  }
}
