@import "/src/assets/styles/variables";

.project {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 140px;
  @media screen and (max-width: $l) {
    width: 90%;
    padding-bottom: 70px;
  }
}

.content {
  color: var(--text_content);
  font-size: var(--fontSetting-normal);
  font-weight: 500;
  line-height: normal;
  .img {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 12px;
  }
  .row {
    padding: 16px 12px;
    border-radius: 2px;
    border: 1px solid var(--input_login_stroke);
    background: var(--input_login_bg);
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
