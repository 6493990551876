@import "/src/assets/styles/variables";

.paymentPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    .customButtonStyle {
      width: 100%;
      height: 42px;
    }
  }
}
