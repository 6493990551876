@import "/src/assets/styles/variables";

.channel-radio {
  margin-bottom: 40px;
  @media screen and (max-width: $m) {
    margin-bottom: 24px;
  }
  .ant-radio-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 16px;
    @media screen and (max-width: $m) {
      grid-template-columns: repeat(2, 1fr);
      gap: 8px 6px;
      width: 100%;
      margin: 0 auto;
    }
    .ant-radio-wrapper {
      border: 1.5px solid #dd994d;
      border-radius: 6px;
      background: transparent;
      color: #000;
      font-size: var(--fontSetting-bigger);
      font-weight: 500;
      align-items: center;
      min-height: 94px;
      padding: 12px 16px;
      margin: 0;
      position: relative;
      @media screen and (max-width: $l) {
        padding: 12px 8px;
      }
      @media screen and (max-width: $s) {
        padding: 8px 0 8px 4px;
        min-height: 54px;
      }
      &.add-radio {
        background: #fff2e5;
        border: 0;
        min-height: 94px;
        @media screen and (max-width: $s) {
          justify-content: center;
          min-height: 60px;
        }
        &::before {
          display: none;
        }
        & > span {
          display: flex;
          align-items: center;
          padding: 0;
          .add-icon {
            font-size: 26px;
            color: #d79a5f;
            margin-right: 8px;
            @media screen and (max-width: $s) {
              font-size: 20px;
            }
          }
        }
        .ant-radio {
          display: none;
        }
      }
      &::before {
        content: "";
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 1px solid #d79a5f;
        @media screen and (max-width: $s) {
          width: 20px;
          height: 20px;
        }
      }
      &.ant-radio-wrapper-checked {
        border: 0;
        background: linear-gradient(94deg, #fff2e2 0%, #f8dcbb 40.5%, #e8b77d 100%);
        &:hover {
          border-color: #dd974a;
        }
        .check-icon {
          display: unset;
        }
      }
      .check-icon {
        margin-right: 12px;
        font-size: 14px;
        color: #d78d5f;
        position: absolute;
        left: 22px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        @media screen and (max-width: $l) {
          left: 14px;
        }
        @media screen and (max-width: $s) {
          left: 9px;
          font-size: 10px;
        }
      }
      .ant-radio {
        display: none;
        &.ant-radio-checked {
          .ant-radio-input {
          }
        }
      }
    }
  }
}
